h1, h2, h3, p {
  margin: 0px;
}

.hrContainer {
  padding-left: 1rem;
  padding-right: 1rem;
}

hr {
  border-color: #EEEEEE25;
  border-width: 1px;
  margin: 0px;
}

navbar {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

navbar h1, footer h1 {
  font-weight: 800;
  font-size: 28px;
  display: flex;
  align-items: center;
}

navbar h1 img, footer h1 img {
  height: 35px;
  margin-right: 0.5rem;
}

navbar a {
  text-decoration: none;
  color: black;
  margin-left: 2rem;
}

.section {
  padding: 1rem;
}

.section h2 {
  font-size: 28px;
  margin-bottom: 1rem;
}

.primaryColor {
  color: #0F4C81;
}

#heroContainer {
  min-height: 100vh;
  width: 100%;
}

#heroCenteredContainer {
  margin-top: 10%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

#heroCenteredContainer div {
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

#heroCenteredContainer h1 {
  font-weight: 700;
  font-size: 57px;
  margin-bottom: 1rem;
}

#heroCenteredContainer p {
  font-size: 23px;
  margin-bottom: 2rem;
}

#heroCenteredContainer img {
  width: 50%;
  margin-bottom: 2rem;
}

#highlightsBanner {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.highlightIconContainer {
  width: 100px;
  height: 100px;
  background-color: #FFFFFF;
  border-radius: 50%;
}

.highlightIconContainer img {
  width: 75%;
}

.highlightContainer.middle {
  margin-left: 2rem;
  margin-right: 2rem;
}

#servicesContainer p {
  margin-bottom: 1rem;
}

.serviceContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.serviceContainer img {

}

.serviceContainer .imagePlaceholder, .serviceContainer img {
  width: 100%;
  height: 150px;
  background-color: #CCCCCC;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}

#servicesContainer .serviceContainer p {
  margin-bottom: 0.5rem;
}

#contactContainer p {
  margin-bottom: 1rem;
}

#contactForm {
  display: flex;
  flex-direction: column;
}

#contactForm input, textarea {
  margin-bottom: 0.5rem;
  padding: 12px 16px;
  border-radius: 100px;
  border: 1px solid #CCCCCC;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

textarea::placeholder {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

button.primaryBtn {
  background-color: #0F4C81;
  color: #FFFFFF;
  padding: 12px 24px;
  border-radius: 100px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

footer {
  padding: 1rem;
  text-align: center;
}

#footerInnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
}

#footerLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

#footerSocials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#footerSocials a.footerSocialIcon {
  display: flex;
  justify-items: center;
  align-items: center;
  background-color: #0F4C81;
  color: white;
  padding: 0.5rem;
  margin-left: 1rem;
  border-radius: 50%;
}

#footerSocials a.footerSocialIcon svg {
  width: 1.5rem;
  height: 1.5rem;
}

footer p {
  text-align: left;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }

  #heroCenteredContainer {
    margin-top: 4%;
    flex-direction: row;
  }

  #heroCenteredContainer img {
    width: 25%;
    margin-bottom: 1rem;
  }

  #heroCenteredContainer div {
    margin-right: 5rem;
    text-align: left;
  }

  #highlightsBanner {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .highlightContainer.middle {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .section {
    padding: 1rem 10rem;
  }

  .serviceContainer .imagePlaceholder, .serviceContainer img {
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
}